export default () => {
  const switches = document.querySelectorAll('.js-drawer-switch')
  const links = document.querySelectorAll('.js-drawer-link a')

  Array.prototype.forEach.call(switches, sw => {
    sw.addEventListener('click', () => {
      document.documentElement.classList.toggle('is-drawer-opened')
    })
  })

  Array.prototype.forEach.call(links, link => {
    link.addEventListener('click', () => {
      document.documentElement.classList.remove('is-drawer-opened')
    })
  })
}
