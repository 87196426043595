export default () => {
  const openers = document.querySelectorAll('.js-dialog-opener')
  const closers = document.querySelectorAll('.js-dialog-closer')

  Array.prototype.forEach.call(openers, opener => {
    opener.addEventListener('click', event => {
      document.documentElement.classList.add('is-dialog-opened')
      event.target.nextElementSibling.classList.add('is-dialog-opened')
    })
  })

  Array.prototype.forEach.call(closers, closer => {
    closer.addEventListener('click', event => {
      document.documentElement.classList.remove('is-dialog-opened')
      event.target.closest('.is-dialog-opened').classList.remove('is-dialog-opened')
    })
  })
}
